import React from "react";
import "./WorkExperience.css";
import { useTranslation } from "react-i18next";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { FaStar } from "react-icons/fa";
import iotech from "../../assets/iotech.jpg";
import tai from "../../assets/tai.png";
import disisleri from "../../assets/disisleri.jpg";

import "react-vertical-timeline-component/style.min.css";
const WorkExperience = () => {
  const { t } = useTranslation();
  const languages = [
    { id: 1, icon: disisleri },
    { id: 2, icon: tai },
    { id: 3, icon: iotech }
  ];
  const experiences = languages.map(language => ({
    title: t(`work_experience.jobTitle${language.id}`),
    company: t(`work_experience.company${language.id}`),
    location: t(`work_experience.location${language.id}`),
    date: t(`work_experience.date${language.id}`),
    icon: language.icon,
    description: t(`work_experience.description${language.id}`),
    url: t(`work_experience.url${language.id}`)
  }));
  return (
    <section id="about" className="about">
      <h5 className="h5text">{t("work_experience.title")}</h5>
      <h2>{t("work_experience.title2")}</h2>
      <VerticalTimeline animate={false} lineColor="rgba(255, 255, 255, 0.6)">
        {experiences.map((experience) => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(33, 150, 243)", color: "rgba(255, 255, 255, 0.6)f" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
            date={experience.date}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "rgba(255, 255, 255, 0.6)" }}
            icon={
              <a href={experience.url}>
                <img 
                  className="workImg" 
                  src={experience.icon} 
                  alt={experience.description ? experience.description : ""} 
                />
              </a>
            }>
            <h3 className="vertical-timeline-element-title">
              {experience.title}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              {experience.location}
            </h4>
            <p>{experience.description}</p>
          </VerticalTimelineElement>
        ))}
        <VerticalTimelineElement
          iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
          icon={<FaStar />}
        />
      </VerticalTimeline>
    </section>
  );
};

export default WorkExperience;
